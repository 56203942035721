import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import "./Process.css";
import Click from "../assets/click.png";
import Info from "../assets/info.png";
import Event from "../assets/appointment.png";
import Payment from "../assets/wallet.png";

const Process = forwardRef((props, ref) => {
  return (
    <motion.div
      variants={props.variants}
      initial={props.initial}
      animate={props.animate}
      whileInView={props.whileInView}
      transition={props.transition}
      viewport={props.viewport}
      ref={ref} // Attach the ref here
      className="process"
    >
      <h1>How We Work</h1>
      <div className="box">
        <div className="up">
          <div className="boxes">
            <img src={Event} alt="Event Icon" />
            <h3>Explore Upcoming Events</h3>
            <p>
              Discover the latest events happening near you! Our upcoming events
              page provides all the details you need, including event dates,
              locations, and exclusive highlights, to help you choose the
              perfect experience.
            </p>
          </div>
          <div className="boxes">
            <img src={Click} alt="Click Icon" />
            <h3>Get Your Ticket</h3>
            <p>
              Once you’ve found an event you’re interested in, simply click on
              the "Get Ticket" button to begin your booking process. Secure your
              spot with just a few clicks!
            </p>
          </div>
        </div>
        <div className="down">
          <div className="boxes">
            <img src={Info} alt="Info Icon" />
            <h3>Enter Your Details</h3>
            <p>
              Provide the necessary information for your ticket reservation,
              such as your name and contact details, to ensure a smooth and
              personalized experience. Then, proceed to checkout.
            </p>
          </div>
          <div className="boxes">
            <img src={Payment} alt="Payment Icon" />
            <h3>Complete Payment</h3>
            <p>
              Finalize your booking by making a secure payment using PayPal,
              credit, or debit card. Once your payment is complete, you're ready
              to attend the event and enjoy!
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
});

export default Process;
