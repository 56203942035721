import React, { useState } from 'react'
import './Navbar.css';
import navbarIcon from '../assets/navbarIcon.png';
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.png";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate =  useNavigate();

  // Function to toggle the navigation menu
  const toggleNav = () => {
   // console.log(isNavOpen);
    setIsNavOpen(!isNavOpen);
  };

  // Function to close the navigation menu
  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <>
      <nav className="navbar">
        <img
          className="logoImage"
          src={Research_Pro_Logo}
          alt="Logo"
          onClick={() => navigate("/")}
        />
        <div>
          <a href="/">Home</a>
          <a href="/upcoming">Events</a>
          <a href="/contactus">Contact Us</a>
        </div>
        {/* <div className="IconImage">
                <img src={navbarIcon} alt="Logo" id="nav-toggle" onClick={toggleNav}/>
            </div> */}
      </nav>

      {/* <div id="nav-content" className={`nav-content ${isNavOpen ? 'open' : ''}`}>
            
            <button id="close-nav" className="close-button" onClick={closeNav}>X</button>

            <div className="logoImage" onClick={()=>navigate("/")}><img src={Research_Pro_Logo} alt="Logo"  />
                <p> Research Pro</p>
            </div>

            <ul>
                <li><a href="/upcoming">

                Upcoming</a></li>
                <li ><a href="/past">Closed</a></li>
            </ul>
        </div> */}
    </>
  );
}

export default Navbar