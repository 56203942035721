import React, { useRef, useState } from "react";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";
import { MdOutlineInsertPageBreak } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { FaTrophy } from "react-icons/fa";
import { motion } from "framer-motion";
import Process from "../pages/Process.js";
import Banner from "../assets/ResearchProBanner.png";
import VideoMain from "../assets/ResearchPro-Video.mp4";
import Poster from "../assets/videoposter.png";
import axios from "axios";

const HomePage = () => {
  // const [inView, setInView] = useState([false, false, false]); // For 3 elements
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const videoRef = useRef(null);

  // Function to handle form submission
 const handleFormSubmit = async (e) => {
   e.preventDefault(); // Prevent page reload on form submit
   try {
     const response = await axios.post(
       "https://researchpro.online/subscribe", // Backend URL
       { email }
     );
     if (response.status === 200) {
       setMessage(
         "Thank you for subscribing! Check your email for the eBook link."
       );
       setEmail(""); // Clear input field
     }
   } catch (error) {
     if (error.response) {
       // Server responded with a status code other than 2xx
       console.error("Server Response Error:", error.response);
       if (error.response.status === 400) {
         setMessage("You already subscribed!");
       } else {
         setMessage("An error occurred. Please try again later.");
       }
     } else if (error.request) {
       // Request was made but no response was received
       console.error("No Response Error:", error.request);
       setMessage(
         "No response from server. Please check your internet connection."
       );
     } else {
       // Something else triggered the error
       console.error("Unexpected Error:", error.message);
       setMessage("An error occurred. Please try again.");
     }
   }
 };


  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const variants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  const testimonialvariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const navigate = useNavigate();
  return (
    <div className="upcoming-page">
      <motion.div
        className="page-header"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
        style={{
          backgroundImage: `url(${Banner})`,
        }}
      >
        <button
          className="highlight-button"
          onClick={() => navigate("/upcoming")}
        >
          Start Learning
        </button>
      </motion.div>

      <motion.div
        className="video-section"
        ref={ref2}
        variants={testimonialvariants} // Define animation states (hidden, visible)
        initial="hidden" // Start hidden
        whileInView="visible" // Animate when in view
        exit="hidden" // Reverse the animation when exiting the viewport
        transition={{ duration: 1, ease: "easeOut" }} // Smooth easing
        viewport={{ once: false, amount: 0.5 }} // Trigger when 30% of the element is in view, both up and down scroll
      >
        <h1>Get Free E-book: "10 Steps to a Winning Proposal."</h1>
        <button onClick={togglePopup}>Get Free E-Book</button>
        <div className="video-container">
          <div className="video-box">
            <video
              poster={Poster}
              ref={videoRef}
              className="video"
              src={VideoMain}
              controls={true}
              loop
            ></video>
          </div>
          <div className="video-content">
            <h2>What is ResearchPro.online?</h2>
            <p>
              ResearchPro is your ultimate online platform that provides
              researchers, and students with the means, strategies, and expert
              guidance to accomplish writing research work successfully. By
              providing resources and support, we help beginners and advanced
              students in writing quality research proposals, articles, and
              thesis.Our platform encompasses live webinars, guided workshops
              conducted by experts, and bespoke resources tailored to clarify
              the intricacies of academic research. We leverage our capabilities
              in every aspect of research, proposal writing, and academic
              success to guide you through every step, from selecting the right
              topic to structuring your proposal and perfecting your
              methodology.ResearchPro.online would give you excellent knowledge,
              insights, hands-on experience, and actionable tips that make your
              research writing experience effective and professional.
            </p>
          </div>
        </div>
      </motion.div>

      {/* Popup */}
      {isPopupVisible && (
        <motion.div
          className="popup-overlay"
          initial={{ opacity: 0 }} // Added animation for popup appearance
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="popup-content"
            initial={{ scale: 0.8 }} // Animation for scaling
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
          >
            <h2>Enter Your Email</h2>
            <form onSubmit={handleFormSubmit} >
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Two-way binding for email input
                required
              />

              <div className="btn-box">
                <button type="submit" className="submit-button">
                  Submit
                </button>
                <button
                  onClick={togglePopup}
                  className="close-btn"
                  type="button" // Changed to type="button" to prevent form submission
                >
                  Close
                </button>
              </div>
            </form>
            {message && <p className="message">{message}</p>}
          </motion.div>
        </motion.div>
      )}

      <motion.div
        className="achivements"
        ref={ref1}
        variants={variants} // Define animation states (hidden, visible)
        initial="hidden" // Start hidden
        whileInView="visible" // Animate when in view
        transition={{ duration: 1, ease: "easeOut" }} // Smooth easing
        viewport={{ once: false, amount: 0.5 }} // Trigger when 30% of the element is in view, both up and down scroll
        exit="hidden" // Reverse the animation when exiting the viewport
      >
        <p>
          We understand the unique challenges and demands that come with
          pursuing advanced academic research. Our series of webinars is
          designed to cover a wide range of research-related topics, providing
          you with in-depth knowledge and practical insights to elevate your
          academic journey.
        </p>
        <div className="highlights">
          <div className="cards">
            <MdOutlineInsertPageBreak
              size={50}
              className="image"
              color="#fbb83b"
            />
            <p className="card-number">5000+</p>
            <h5 className="card-text"> Scholars assists</h5>
          </div>

          <div className="cards">
            <IoIosPeople size={50} className="image" color="#fbb83b" />
            <p className="card-number">7+</p>
            <h5 className="card-text"> Years of Experience</h5>
          </div>
          <div className="cards">
            <FaTrophy size={50} className="image" color="#fbb83b" />
            <p className="card-number">120+</p>
            <h5 className="card-text"> Countries Reached</h5>
          </div>
        </div>
        <button
          className="highlight-button"
          onClick={() => navigate("/upcoming")}
        >
          {" "}
          See Upcoming Events{" "}
        </button>
      </motion.div>

      <motion.div
        className="testimonials"
        ref={ref2}
        variants={testimonialvariants} // Define animation states (hidden, visible)
        initial="hidden" // Start hidden
        whileInView="visible" // Animate when in view
        exit="hidden" // Reverse the animation when exiting the viewport
        transition={{ duration: 1, ease: "easeOut" }} // Smooth easing
        viewport={{ once: false, amount: 0.5 }} // Trigger when 30% of the element is in view, both up and down scroll
      >
        <h1 className="testimonials-header">Testimonials </h1>
        <div className="testimonials-items">
          <div className="cards">
            <p className="quotes">
              "I’ve attended several webinars hosted by ResearchPro, and each
              one has exceeded my expectations. The interactive format and
              expert speakers make complex topics accessible and engaging. It’s
              a fantastic platform for any researcher!"
            </p>
            <p>— Maria, Postdoctoral Researcher</p>
          </div>
          <div className="cards">
            <p className="quotes">
              "I’ve found ResearchPro’s webinars to be incredibly valuable. The
              expert-led sessions offer deep dives into critical research areas
              and provide actionable advice that has directly improved my
              research outcomes"
            </p>
            <p>— Sophia, Postdoctoral Fellow</p>
          </div>
          <div className="cards">
            <p className="quotes">
              "The resources and webinars offered by ResearchPro are
              unparalleled. The platform has been a fantastic aid in my
              research, providing expert guidance and a wealth of knowledge that
              has greatly enhanced my academic performance."
            </p>
            <p>— Olivia Wilson, Master's Student</p>
          </div>
        </div>
      </motion.div>

      {/* Add Process component with animations */}
      <Process
        ref={ref3}
        variants={variants} // Define animation states (hidden, visible)
        initial="hidden" // Start hidden
        whileInView="visible" // Animate when in view
        exit="hidden" // Reverse the animation when exiting the viewport
        transition={{ duration: 1, ease: "easeOut" }} // Smooth easing
        viewport={{ once: false, amount: 0.5 }} // Trigger when 30% of the element is in view, both up and down scroll
      />
    </div>
  );
};

export default HomePage;
