import React, { useState } from "react";
import "./contactus.css";
import "react-international-phone/style.css";
import Email from "../assets/email.png";
import Socail from "../assets/social.png";
import LinkedIn from "../assets/linkedin.png";
import Facebook from "../assets/facebook.png";
import emailjs from "@emailjs/browser";

const countryCodes = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+86", name: "China" },
  { code: "+91", name: "India" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+55", name: "Brazil" },
  { code: "+34", name: "Spain" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+52", name: "Mexico" },
  { code: "+60", name: "Malaysia" },
  { code: "+65", name: "Singapore" },
  { code: "+64", name: "New Zealand" },
  { code: "+20", name: "Egypt" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+1", name: "Canada" },
];

const ContactUs = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setmessage] = useState("");
  const [countryCode, setCountryCode] = useState("+1"); // Default to the US

  const handleSubmit = (event) => {
    event.preventDefault();

    // Combine country code and phone number
    const fullPhoneNumber = `${countryCode} ${phone}`;

    // Log form data to verify values
    console.log("Form Data:", { name, email, message, fullPhoneNumber });

    // EmailJS service configuration
    const serviceId = "service_vuuwuwp"; // Replace with your service ID
    const templateId = "template_no6j63a"; // Replace with your template ID
    const publicKey = "TG2-PMYez-wQOWPEK"; // Replace with your public key

    // Prepare the data for EmailJS template
    const templateParams = {
      customer_name: name,
      customer_email: email,
      customer_message: message,
      customer_contactnumber: fullPhoneNumber,
    };

    // Send email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully:", response);

        // Reset form fields after successful submission
        setname("");
        setemail("");
        setmessage("");
        setPhone("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <div>
      <h1>Get In Touch</h1>
      <div className="cont">
        <form method="post" onSubmit={handleSubmit} className="contact-form">
          <label htmlFor="name">
            <input
              required
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </label>
          <label htmlFor="email">
            <input
              required
              type="email"
              placeholder="Email Id"
              name="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </label>
          <label htmlFor="mobile">
            <div className="phone-input-container">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="country-code"
              >
                {countryCodes.map((country, index) => (
                  <option key={`${country.code}-${index}`} value={country.code}>
                    {country.code} ({country.name})
                  </option>
                ))}
              </select>
              <input
                required
                type="tel"
                placeholder="Mobile number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="mobile-number"
              />
            </div>
          </label>
          <label htmlFor="message">
            <textarea
              required
              placeholder="Write Your Message Here..."
              name="message"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            ></textarea>
          </label>
          <button id="message-contact" className="c-btn" type="submit">
            Submit
          </button>
        </form>
        <div className="contact-info">
          <h1>Contact Information</h1>
          <h2>
            <img src={Email} alt="Email Icon" /> Email:
          </h2>
          <p>info@researchpro.com</p>
          <h2 className="social-head">
            <img src={Socail} alt="Social Media Icon" /> Follow Us On:
          </h2>
          <p className="social-ico">
            <a
              href="https://www.facebook.com/profile.php?id=61551675300150"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="fb-img" src={Facebook} alt="Facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/researchevents/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
