import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./pages/Navbar";
import "./App.css";
import HomePage from "./pages/HomePage";
import UpcomingEvent from "./pages/UpcomingEvent";
import PastEvents from "./pages/PastEvents";
import AddEvent from "./pages/AddEvent";
import Footer from "./pages/Footer";
import Analytics from "./analytics/Analytics";
import TrackPageView from "./analytics/TrackPageView";
import ThankyouPage from "./pages/Thankyou/ThankyouPage";
import ContactUs from "./pages/contactus.js";

const App = () => {
  return (
    <Router>
      <div className="page-container">
        <Analytics /> {/* Initialize Google Analytics */}
        <Navbar />
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/upcoming" element={<UpcomingEvent />} />
          {/* <Route path="/past" element={<PastEvents />} /> */}
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/addEvent" element={<AddEvent />} />
          <Route path="/thank-you" element={<ThankyouPage />} />
        </Routes>
        <TrackPageView /> {/* Track page views on route changes */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
